import Food from './images/food_oct_23.png';
import drink1 from './images/cocktails_oct_23.png'
import drink2 from './images/cocktails_oct_23_2.png'
import wine1 from './images/wines_oct_23.png'
import wine2 from './images/wines_oct_23_2.png'
import useViewport from './utils';

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

const Menus = () => {

    const { isMobile } = useViewport();

    return (
        <div>
            <div className={isMobile ? "header-fourteen-mobile" :"header-fourteen"}>Menus</div>
            <div className="menu top">
                <img className={isMobile ? "menu-image-mobile" : "menu-image"} src={Food} alt="Food Menu" onClick={() => openInNewTab(this.src)}/>
            </div>
            <div className="menu middle">
                <img className={isMobile ? "menu-image-mobile" : "menu-image"} src={drink1} alt="Drinks Menu 1" onClick={() => openInNewTab(this.src)}/>
            </div>
            <div className="menu middle">
                <img className={isMobile ? "menu-image-mobile" : "menu-image"} src={drink2} alt="Drinks Menu 2" onClick={() => openInNewTab(this.src)}/>
            </div>
            <div className="menu middle">
                <img className={isMobile ? "menu-image-mobile" : "menu-image"} src={wine1} alt="Drinks Menu 2" onClick={() => openInNewTab(this.src)}/>
            </div>
            <div className="menu bottom">
                <img className={isMobile ? "menu-image-mobile" : "menu-image"} src={wine2} alt="Drinks Menu 2" onClick={() => openInNewTab(this.src)}/>
            </div>
            <div className="temp-menu-footer"/>
        </div>
    )

};

export default Menus;