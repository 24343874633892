import { Player } from '@lottiefiles/react-lottie-player';
import {Link, useLocation} from "react-router-dom";
import useViewport from "./utils";
import backArrow from './images/back-arrow.png';
import lottie from './images/logo.json';

const Header = () => {

    const { isMobile } = useViewport();

    return (
        <div className={isMobile ? "logo-mobile" : "logo-container"}>
            {
                (useLocation().pathname === '/menus')
                &&
                <Link to="/">
                    <div>
                        <img className={isMobile ? "back-arrow-mobile" : "back-arrow"} src={backArrow} alt="back"/>
                    </div>
                </Link>}
            <Link to="/">
                <span className="logo-span" style={{"display": "block"}}>
                    <Player
                    src={lottie}
                    className="player"
                    loop
                    autoplay
                    />
                </span>
            </Link>
        </div>
    )

};

export default Header;