import React from "react";

function useViewport() {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    const breakpoint = 620;

    const isMobile = width < breakpoint;

    // Return the width so we can use it in our components
    return { isMobile };
}

export default useViewport;