import {Link} from 'react-router-dom';
import useViewport from './utils';
import PhotoStrip from "./images/photo-strip.png";
import PhotoStripMobile from "./images/photo-strip-mobile.png";

const HomeContent = () => {

    const { isMobile } = useViewport();

    return (
        <div>
            <div className={isMobile ? "header-fourteen-mobile" : "header-fourteen"}>Fourteen</div>
            <div className={isMobile ? "bio-mobile" : "bio-restaurant"}>
                A neighbourhood restaurant and bar
                in Tooting Bec serving small plates from Europe
                alongside classic and natural wines, cocktails
                and craft beers.
            </div>
            <div className={isMobile ? "our-menus-mobile" : "our-menus"}><Link to="/menus">our menus</Link></div>
            <a href="https://goo.gl/maps/jbFnLPWxTbx87u6ZA"><div className={isMobile ? "address-mobile" : "address"}>14 Trinity Road, London, SW17 7RE</div></a>
            <a href="https://www.instagram.com/fourteentootingbec"><div className={isMobile ? "insta-handle-mobile" : "insta-handle"}>@fourteentootingbec
            </div></a>
            { isMobile ?
                <div className="resdiary-button">
                    <a href="https://booking.resdiary.com/widget/Standard/Fourteen/37400">
                        <img src= "https://resdiary.blob.core.windows.net/uploads/uk/3133/images/5/img13371.png"
                             className="book-now-button" alt="Book.ResDiary" /></a>
                </div>
                :
                <div className="resdiary-container">
                    <iframe className="resdiary-widget" title="ResDiaryWidget" src="https://booking.resdiary.com/widget/Standard/Fourteen/37400"/>
                </div>
            }
            <div className={isMobile ? "hours-mobile" :"hours"}>Opening hours:</div>
            <div className={isMobile ? "opening-mobile" :"opening"}>
                <br/>
                <br/>
                Mon-Thurs: 5pm-11pm
                <br/>
                <br/>
                Fri-Sat: 4pm-1am
                <br/>
                <br/>
                Sun: 4pm-10pm
            </div>
            <div className="image-strip-container">
                <img className="image-strip" src={isMobile ? PhotoStripMobile : PhotoStrip} alt="Strip"/>
            </div>
            <div className="big-margin"/>
        </div>
    )

};

export default HomeContent;