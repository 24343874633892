import './App.css';
import Header from './Header';
import HomeContent from './HomeContent';
import WebFont from 'webfontloader';
import {useEffect} from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Menus from "./Menus";

function App() {
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Karla']
            }
        });
    }, []);
  return (
    <div className="bg">
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route path="/" element={<HomeContent/>}/>
                <Route path="/menus" element={<Menus/>}/>
                <Route path="*" element={<HomeContent/>}/>
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
